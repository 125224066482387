.dev-container .head {
	padding-top: 25px;
	overflow: hidden;
	margin-bottom: 7px;
}

.article {
	padding: 25px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 1px solid #dcdcdc;
}

.article .article_block {
	margin-bottom: 35px;
	border-bottom: 1px solid #ebebeb;
}

.article .article_title {
	cursor: pointer;
}

.image_panel {
	height: 95px;
	width: 95px;
}

.article h4 {
	color: #2269b0;
	font-weight: bold;
	text-decoration: underline;
}

.article p {
	margin-top: 10px;
	color: #999;
	margin-bottom: 30px;
}

.header-section-1 {
	margin-bottom: 7px;
}

.dev-container .com-name {
	font-size: 20px;
	font-weight: bold;
	color: #1A203B;
	margin-bottom: 7px;
}

.dev-container .com-address {
	font-size: 14px;
	font-weight: bold;
	color: #fff;
}

.com-mobile {
	width: 192px;
	height: 29px;
	display: inline-block !important;
	color: #fff;
	background-color: #ff874a;
	margin-bottom: 15px !important;
	padding: 2px 10px;
	margin: 15px 0 5px 0;
	border-radius: 5px;
}

.enq-bar a {
	padding: 0 !important;
}

.dropdown a {
	padding-left: 12px !important;
	padding-right: 12px !important;
}

a.contact {
	border-right: 0;
}

.dev-logo {
	margin-top: 30px;
}

.req-btn button {
	width: 100%;
	height: 40px;
	background: #c2362b;
	border-radius: 7px;
	border: 1px solid #c2362b !important;
	transition: all 0.3s;
	font-size: 19px;
	color: #ffffff;
	margin-top: 15px;
	margin-bottom: 10px;
	outline: 0;
}

.dev-container .com-email,
a {
	font-size: 12px;
	color: #333365;
	/* margin-top: 8px; */
	font-weight: bold;
	text-decoration: none !important;
	cursor: pointer;
}

.social_media {
	margin-top: 15px;
}

.social {
	float: left;
}

.social-link {
	/* background: url(./media/images/selenium-logo.png); */
	width: 34px;
	height: 34px;
	display: block;
	zoom: 0.7;
	margin-left: 7px;
	cursor: pointer;
}

.gm {
	background-position: -36px;
}

.in {
	background-position: -106px;
}

.tw {
	background-position: -212px;
}


/* banner */

.banner {
	box-shadow: 10px 10px 10px;
}

.news {
	padding-top: 10px;
}

.navigation .navbar-header {
	display: none;
}


/*footer*/

.dev-footer {
	background-color: #205ea2;
}

.footer-bottom {
	color: #ffffff;
	font-size: 12px;
	text-align: center;
	padding: 7px;
}

.dev-footer a {
	font-size: 13px;
	color: #ffffff;
	cursor: pointer;
}

.dev-footer ul {
	list-style: none;
	padding-left: 0;
}

.dev-footer .footer-top {
	padding: 25px;
	margin: 0 auto;
	width: 80%;
}

.dev-footer .footer-top ul li {
	padding: 8px;
}

.dev-footer .footer-top ul li a {
	padding: 5px;
}

.navigation nav a {
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	color: #ffffff !important;
}

.dropdown-menu li a.active {
	color: #15cdfc !important;
}


.footer-bottom-panel {
	padding: 0 !important;
}

.dev-footer .grid {
	width: 25%;
	float: left;
}


/* homepage */

.middle-font {
	font-family: Georgia, Arial, Helvetica, sans-serif;
}

.homepage {
	margin-top: 25px;
}

.dropList {
	font-size: 14px;
	line-height: 1.42857143;
	color: #262626;
	padding: 10px 12px;
	font-weight: bold;
	cursor: pointer;
}

.homepage h4 {
	font-size: 21px;
}

.homepage h5 {
	font-size: 16px;
}

.justify {
	text-align: justify;
	line-height: 16px;
}

.homepage .left {
	border: 1px solid #999;
	padding: 10px;
}

div#testimonial {
	max-height: 400px;
}

.homepage .testimonial_panel {
	padding-left: 15px;
	padding-right: 15px;
	background: #f8f9fb;
	border-radius: 5px;
	color: #000000;
	padding: 20px;
	margin-top: 25px;
	text-align: center;
	display: none;
	border: 1px solid #e8e8e8;
}

p.comment {
	padding-left: 25px;
	padding-right: 25px;
	color: #382e36;
}

#testiPanel_0 {
	display: block;
}

.name_txt {
	font-size: 55px;
	color: #ffffff;
	background: #333367;
	height: 125px;
	width: 125px;
	padding: 25px;
	display: none;
}

.homepage .testimonial .header {
	font-size: 18px;
	margin-bottom: 32px;
	color: #c2362b;
}

.homepage .right {
	overflow: hidden;
}

.dev-tab li a {
	background: #e4e4e4;
}

.dev-tab-content {
	background-color: #ffffff;
	padding: 15px;
	border: 1px solid #ddd;
	border-top: 0;
	margin-bottom: 15px;
}
.dev-tab-content .tabPanel {
	height: 28rem;
	overflow: hidden;
}
.homepage .section-1 {
	margin-bottom: 15px;
}

.dev-tab-content h3 {
	color: #BF5A14;
	margin-top: 0;
	margin-bottom: 15px;
	font-size: 20px;
}

.home-sec-1-pad-l-0 {
	padding-left: 0;
}

.fontsize-22 {
	font-size: 22px;
	margin-top: 5px;
	margin-bottom: 20px;
}


/* course */

.course-page .list {
	margin-bottom: 25px;
}

.course-page .list li {
	list-style: none;
}

.course-page {
	margin-bottom: 50px;
	padding-top: 20px;
}

.contactus-page {
	padding-bottom: 10px;
	padding-right: 0px;
	padding-left: 0px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 1px solid #dcdcdc;
}

.contactus-page .ora-color {
	font-family: 'Open Sans', sans-serif;
	color: #ff6700;
	font-weight: bold;
	margin-bottom: 15px;
}

.contactus-page .heading {
	font-size: 20px;
}

.contactus-page .subheading {
	font-size: 18px;
}

.contactus-page .address {
	color: #757575;
}

.contactus-page .address p {
	margin-bottom: 5px;
	font-size: 15px;
}

.contactus-page .address p a {
	color: #0066cc;
	margin-top: 8px;
	display: block;
	font-size: 14px;
	text-decoration: underline;
}

.contactus-page .form-horizontal .control-label {
	text-align: left !important;
}

.contactus-page .featured ul {
	padding: 0;
}

.contactus-page .featured ul li {
	list-style: none;
	margin-bottom: 10px;
	font-size: 18px;
}

.dev-form-control {
	padding: 7px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid #afafaf;
}

.contactus-page .control-label {
	font-size: 12px;
}

.contactus-page .message-header {
	font-size: 16px;
	margin-top: 0;
	color: #000000;
	font-weight: bold;
	margin-bottom: 25px;
	text-align: center;
}

.section-c-1 {
	margin-bottom: 30px;
	margin-top: 7px;
}

.courseboxmid {
	background-color: #f9f9f9;
	padding: 15px;
	border: 1px solid #999999;
	border-radius: 3px;
	margin-top: 15px;
}

.course-page .customer {
	background-color: #ddd8a8;
	padding: 35px 0;
	margin-bottom: 10px;
}

.course-page .customer h2 {
	font-size: 20px;
	text-align: center;
	color: #000000;
}

.course-page .customer .inner {
	text-align: center;
}

.course-page .customer .icon {
	color: #65beea;
	font-size: 75px;
}

.course-page .customer h4 {
	font-size: 16px;
	color: #000000;
}

.course-page .desc {
	margin-bottom: 22px;
}

.dynamic-res-img {
	margin: 29px auto !important;
	background-color: #9de5f1;
}

.course-page .customer p {
	padding: 0 35px;
}

.req-btn-course button {
	margin-top: 15px;
	margin-bottom: 32px;
}

.course-side-panel {
	margin-bottom: 15px;
}

.course-side-panel button {
	background-color: #0fb6da;
}

.course-side-panel button:hover {
	background-color: #036b82;
}

.course-page .youtube_tbu {
	text-align: center;
	border: 1px solid #999999;
	padding: 55px;
	background: #333333;
	color: #ffffff;
}

.enq-form {
	text-align: center;
	border: 1px solid;
	background: rgba(0, 0, 0, 0.9);
	padding: 20px;
	color: #ffffff;
}

.enq-btn {
	background-color: #ffc300;
	color: #ffffff;
}

.enq-form .line {
	background: #ffc300;
	height: 2px;
	width: 70px;
	margin: 0 auto;
}

.footer-sesion-2 {
	background-color: #060c2e;
	font-weight: 600;
	font-size: 16px;
	padding-bottom: 20px;
}

.footer-middle {
	margin: 0 auto;
	overflow: hidden;
	display: inline-block;
	padding-top: 15px;
}

.col-md-12.footer-middle-panel {
	text-align: center;
}

.footer-middle-panel li {
	float: left;
	width: 48px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin: 0 auto;
	height: 49px;
	overflow: hidden;
}

.footer-middle-panel li a {
	font-size: 25px;
}

.footer-middle-panel li:nth-child(1) {
	width: 100px;
	color: #ffffff;
}

.footer-middle-panel li:nth-child(1) h5 {
	font-size: 18px
}

.footer-bottom-panel .footer-bottom {
	font-size: 14px;
	color: #f9ca36;
}

.footer-bottom-panel p {
	text-align: center;
	color: #ffffff;
}

.dynamic-banner {
	box-shadow: 2px 2px 4px #6c6c6c;
	background-color: #e3f8ef;
	padding: 40px 15px;
}

.ba-header {
	font-size: 24px;
	color: #314451;
	padding: 8px;
	font-weight: bold;
	display: block;
}

span.ratings {
	font-size: 15px;
	font-weight: bold;
	color: #2b101c;
}

.rate-panel {
	background: #9de5f1;
	padding: 5px;
	overflow: hidden;
}

.card {
	overflow: hidden;
	background: #9de5f1;
	margin-bottom: 10px;
	margin-top: 15px;
	border-radius: 15px;
}

.card h5 {
	font-size: 16px;
	font-weight: bold;
	color: #314451;
}

.banner-img {
	width: 45px;
	height: 45px;
	position: absolute;
	top: 7px;
	border-radius: 50%;
	overflow: hidden;
	text-align: center;
	font-size: 22px;
	padding-top: 8px;
	color: #ffffff;
	background: #003366;
	border: 3px solid white;
}

.logo-text {
	font-size: 15px;
	font-weight: bold;
	color: #060c2e;
}

.jobs {
	margin-top: 15px;
	margin-bottom: 15px;
	border: 1px solid #dcdcdc;
}

.jobs .show_more {
	display: inline-block;
	cursor: pointer;
	color: #205ea2;
	font-weight: bold;
}

.jobs .section {
	border-bottom: 1px solid #eeeeee;
	padding: 10px 0;
	overflow: hidden;
	position: relative;
}

.jobs .content {
	margin-left: 15px;
}

.jobs .short_desc {
	color: #999999;
}


.message-session {
	border: 1px solid #999999;
	background-color: #f9f9f9;
	padding: 15px;
	padding-bottom: 3px;
	margin-top: 15px;
	border-radius: 3px;
}

.skills {
	margin-bottom: 25px;
}

.send_resume {
	margin-top: 20px;
}

.inner-article {
	margin-top: 15px;
	padding: 20px;
	border: 1px solid #dcdcdc;
}

.article-details {
	margin: 15px -15px;
}

.article-details .descrip {
	margin-top: 20px;
	margin-bottom: 20px;
}

.article-details .coding {
	overflow: hidden;
	margin-bottom: 15px;
}

.output {
	overflow: hidden;
	margin-bottom: 15px;
}

.card_decri {
	font-size: 11px;
}

.content {
	overflow: hidden;
	margin-top: 7px;
}

.star-outer {
	position: relative;
	display: inline-block;
}

.star-inner {
	position: absolute;
	top: 0;
	left: 0;
	white-space: nowrap;
	overflow: hidden;
	width: 0;
}

.ratings {
	color: #314451;
	font-weight: bold;
}

.star-outer::before {
	content: "\f005 \f005 \f005 \f005 \f005";
	font-family: "FontAwesome";
	font-weight: 900;
	color: #cccccc;
}

.star-inner::before {
	content: "\f005 \f005 \f005 \f005 \f005";
	font-family: "FontAwesome";
	font-weight: 900;
	color: #f8ce0b;
}

.star-inner::before {
	content: "\f005 \f005 \f005 \f005 \f005";
	font-family: "FontAwesome";
	font-weight: 900;
	color: #f8ce0b;
}

.logo {
	margin-bottom: 5px;
	display: inline-block;
	margin: 0px 50px 5px;
}

.profile_img {
	width: 125px;
	height: 125px;
	margin: 0 auto;
	border-radius: 50%;
	overflow: hidden;
	margin-bottom: 22px;
	background: #ffffff;
}

.comRClass {
	margin-bottom: 10px;
	color: #96969b;
	font-size: 13px;
}

.profile_name {
	margin-bottom: 10px;
	font-size: 15px;
	font-weight: bold;
	color: #333365;
}

.enquiry-form {
	margin-bottom: 15px;
	margin-top: 7px;
}

@media only screen and (max-width: 767px) {
	.logo {
		display: inline;
		margin: 0;
	}

	.ba-header {
		font-size: 20px;
		margin-bottom: 5px;
	}

	.header-section-1 {
		display: none;
	}

	.for-desktop {
		display: none;
	}

	.for-mobile {
		display: block;
	}

	.navigation {
		position: fixed;
		width: 100%;
		z-index: 10000;
	}

	.navigation .nav-color {
		background: #060c2e;
		color: #ffffff;
	}

	.navigation a {
		color: #ffffff;
	}

	.navigation .icon-bar {
		background: #ffffff;
	}

	.navigation .navbar-toggle {
		float: left;
		padding: 9px 20px;
		margin: 3px 0px;
		margin-left: 10px;
	}

	.nav-menu {
		margin-top: 5px;
		text-align: center;
		margin-left: -5px;
	}

	.enquiry-menu {
		margin-top: 2px;
		text-align: center;
		font-size: 12px;
	}

	.menu-bar {
		border-right: 1px solid #ffffff;
	}

	.enq-bar {
		border-left: 1px solid #ffffff;
		padding: 0;
	}

	.enq-img {
		background: url(./media/images/enquiry.png);
		background-repeat: no-repeat;
		width: 22px;
		background-size: 100%;
		height: 22px;
		margin-top: 8px !important;
		margin: 0 auto;
	}

	.enq-panel {
		/* border: 1px solid; */
		padding: 3px 0px 11px 0px;
		margin-right: 10px;
	}

	.mobile-banner {
		padding-top: 60px;
	}

	.mobile-news {
		margin-top: 10px;
		margin-bottom: 10px;
		padding: 5px;
		background: #060c2e;
		color: #ffffff;
		font-size: 11px;
		border: 1px solid;
	}

	.navigation .navbar-header {
		display: block;
	}

	.dev-footer .footer-top {
		width: 100%;
	}

	.dev-footer .grid {
		width: 100%;
		float: left;
	}

	.footer-top {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.home-sec-1-pad-l-0 {
		padding-left: 15px;
	}

	.mar-l p {
		margin-top: 10px;
		padding: 15px;
		padding-bottom: 0;
	}

	.dev-tab-content {
		border-top: 1px solid #dddddd;
	}

	.section-1 .left .mobile-view-img {
		margin: 0 auto;
	}

	.dev-form-control {
		width: 100%;
	}

	.banner-image {
		text-align: center;
		margin-bottom: 30px;
	}

	.inner_text_js1 {
		width: 288px;
		margin: 0 auto;
	}

	.logo-text {
		color: #ffffff;
		font-size: 11px;
		margin-top: 5px;
		margin-bottom: -5px;
	}

	.logo-pannel img {
		margin-top: 3px;
	}

	.logo-pannel {
		text-align: center;
	}

	.image_panel {
		margin: 0 auto;
		display: none;
	}

	.dropdown-menu>li>a {
		color: #999999;
	}

	.jobs {
		margin: 20px 0px 15px;
		border: 1px solid #dcdcdc;
	}

	.article {
		margin: 0px 0px 15px;
	}

	.article-details {
		margin: 0;
		margin-bottom: 15px;
	}

	.side-menu {
		margin-left: 15px;
		margin-right: 15px;
	}

	.maps {
		width: 93%;
	}

	.pr-0 {
		padding-right: 15px;
	}

	#info-banner {
		display: block;
	}

	.dropdown-menu li a {
		color: #00bcd4 !important;
	}

	.nav .open>a,
	.nav .open>a:focus,
	.nav .open>a:hover {
		background-color: #ffffff;
		border-color: #337ab7;
		color: #000000 !important;
	}

	.nav>li>a:focus, .nav>li>a:hover{
		background-color: #ffffff !important;
		color: #000000 !important;
	}

	.btn-primary {
		width: 100%;
	}

	.enq-panel a {
		display: block;
	}
	.nav-tabs, .nav-tabs>li {
		width: 100%;
	}
}

@media only screen and (min-width: 767px) {
	.dev-footer .footer-top {
		width: 100%;
	}

	.dev-footer .footer-top ul li a {
		padding: 0;
	}

	.maps {
		width: 97%;
	}

	.pr-0 {
		padding-right: 0;
	}

	#info-banner {
		display: none;
	}

	.dropdown-menu li a {
		color: #333333 !important;
	}

	.p-0 {
		padding: 0px 15px 5px 5px;
	}
}

.testimonial_panel::-webkit-scrollbar {
	width: 5px;
}

.testimonial_panel::-webkit-scrollbar-thumb {
	background-color: #f2f2f2;
}

/* .homepage {
	display: none;
} */



#course-page {
	display: none;
}

.newsWrapper {
	float: left;
	height: 20px;
	overflow: hidden;
	position: relative;
}

.newsLine {
	display: block;
	z-index: 2;
	position: relative;
}

.newsInterface {
	margin: 0 5px 0 0 !important;
}

.nextprev {
	height: 13px;
	width: 13px;
	border-radius: 100%;
	border: 0;
	margin: 2px;
	position: relative;
	padding: 0px;
	font-size: 9px;
	color: #333365;
	top: 1px;
}

.nextprev:hover {
	background-color: #fff695;
}

.nextprev .glyphicon {
	top: -2px;
}

#jsn-content-scrolling {
	background: none repeat scroll 0 0 #333365;
	border: 1px solid #DADADA;
	color: #FFFFFF;
	height: 26px;
	margin-top: 10px;
	overflow: hidden;
	padding: 2px 0 4px;
	position: relative;
	z-index: 88;
}

.maps {
	margin: 15px;
	border: 3px solid #dcdcdc;
	border-radius: 5px;
	background: #ffffff;
	padding: 15px;
}

.jobs-show {
	display: block;
	transition: 2s;
}

.jobs-hide {
	display: none;
	transition: 2s;
}

.form-group.required .control-label:after {
	content: "*";
	color: red;
}

.control-label {
	text-align: left !important;
	padding: 7px 0px 7px 15px;
}