/*
@font-face {
    font-family: Poppins;
    src: url(../media/fonts/Poppins-Light.otf);
}
*/
body {
	font: 13px Arial, Helvetica, sans-serif;
	color: #535353;
	background: #FFFDE4 url(./media/images/bg.jpg) left top repeat-x;
	margin: 0px;
	padding: 0px;
	/*font-family: Poppins;*/
}

span#phNumber {
	font-size: 22px;
}

.udrline {
	text-decoration: underline;
}

.txt-center {
	text-align: center;
}

.nav-color {
	background: #1e5799;
	background: -moz-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #1e5799), color-stop(50%, #2989d8), color-stop(51%, #207cca), color-stop(100%, #7db9e8));
	background: -webkit-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
	background: -o-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
	background: -ms-linear-gradient(top, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
	background: linear-gradient(to bottom, #1e5799 0%, #2989d8 50%, #207cca 51%, #7db9e8 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#7db9e8', GradientType=0);
}


.navigation a {
	color: #ffffff;
	font-size: 14px;
	padding: 10px 12px !important;
}

.dropdown-menu>li>a:hover {
	background-color: #d6d6d6;
}


.navigation a:focus {
	background-color: transparent !important;
}

.navigation .navbar {
	min-height: auto !important;
}

.homemenu {
	border-left: 0 !important;
}


.for-mobile {
	display: none;
}

.padd-l-0 {
	padding-left: 0;
}

.padd-r-0 {
	padding-right: 0;
}

.black-colo {
	color: #000000;
}

.course-sub-title {
	font: bold 16px Georgia, Arial, Helvetica, sans-serif;
	color: #c2362b;
	padding: 0px 0px 5px 0px;
	margin: 0px 0px 10px 0px;
}

.accordion {
	background-color: #eee;
	color: #fff;
	cursor: pointer;
	padding: 10px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	font-weight: bold;
	transition: 0.6s;
}

.side-static-menu {
	background-color: #0fb6da;
	color: #fff;
	cursor: pointer;
	padding: 10px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	font-weight: bold;
	transition: 0.6s;
}

.accordion .active,
.accordion:hover {
	background-color: #ccc;
}

.accordion:after {
	content: '\002B';
	color: #777;
	font-weight: bold;
	float: right;
	margin-left: 5px;
}


.accordion.active:after {
	content: "\2212";
}

.course-side-panel .side-list {
	list-style: none;
	padding: 0;
	margin-bottom: 0;
}

.accordion-panel {
	max-height: 0;
	overflow: hidden;
	transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.accordion-panel.show {
	height: auto;
	max-height: 9999px;
	overflow: hidden;
	transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.course-side-panel .side-list li a {
	color: #343434;
}

.course-side-panel .side-list li {
	padding: 8px;
	padding-left: 20px;
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #fff;
	color: #343434;
	background: #9de5f1;
}

.navigation .navbar {
	margin-bottom: 0px !important;
}

.comment-hide {
	display: none;
}

.comment-show {
	display: block;
}

@media (min-width: 1281px) {
	.dev-container {
		width: 950px;
	}

	.navigation .navbar-nav li:nth-child(1) {
		border: 0;
	}

	.navigation .navbar-nav li {
		border-left: 1px solid #ffffff;
	}

	.navigation .container-fluid {
		padding: 0 !important;
	}

	.navigation .navbar-collapse {
		padding: 0 !important;
	}


	.sidebar-nav {
		padding: 9px 0;
	}


	.dropdown-menu .sub-menu {
		left: 100%;
		position: absolute;
		top: 0;
		visibility: hidden;
		margin-top: -1px;
	}


	.dropdown-menu li:hover .sub-menu {
		visibility: visible;
	}


	.dropdown:hover .dropdown-menu {
		display: block;
	}


	.nav-tabs .dropdown-menu,

	.nav-pills .dropdown-menu,

	.navbar .dropdown-menu {
		margin-top: 0;
	}


	.navbar .sub-menu:before {
		border-bottom: 7px solid transparent;
		border-left: none;
		border-right: 7px solid rgba(0, 0, 0, 0.2);
		border-top: 7px solid transparent;
		left: -7px;
		top: 10px;
	}


	.navbar .sub-menu:after {
		border-top: 6px solid transparent;
		border-left: none;
		border-right: 6px solid #fff;
		border-bottom: 6px solid transparent;
		left: 10px;
		top: 11px;
		left: -6px;
	}

	.contact {
		border-right: 1px solid;
	}

	.navigation .nav>li>a:hover {
		background: #333365 !important;
	}

	.mar-l {
		margin-left: -30px;
	}

}

#message {
	color: #007f00;
	font-weight: bold;
}

.err_message {
	color: tomato;
	display: block;
	text-align: left;
}

.youtube_panel {
	margin-bottom: 10px;
}


body::-webkit-scrollbar {
	width: 8px;
}

body::-webkit-scrollbar-track {
	background-color: #5d5d5d;
}

body::-webkit-scrollbar-thumb {
	background-color: #28aad5;
	border-radius: 10px
}

.marquee {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	height: 286px;
}
ul#contactusCourseList a {
    color: #333365;
}
.requestForDemoClass{
	color: #c2362b;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
}

.submitBtn {
    padding: 0px 80px;
}
.message-session-home {
	border: 1px solid #999999;
    background-color: #f9f9f9;
    padding: 15px;
    padding-bottom: 3px;
    border-radius: 15px;
	margin-bottom: 10px;
}


#info-banner {
	margin: 15px 0px;
    background-color: #e3f8ef;
    border-radius: 10px;
    box-shadow: 0px 0px 3px #333333;
    padding: 15px 10px;
}

.dev-form-control:focus-visible {
    outline: 1px solid #333367 !important;
}

.err_message.show{
	display: block;
}
.err_message.hide{
	display: none;
}

.displayMsg {
	font-size: 14px;
    font-weight: bold;
	margin-bottom: 10px;
}
.thanksMsg {
    color: #4bb543;
}

.issueMsg {
	color: #ff6347;
}
.pfull-0 {
	padding: 0;
}

.mt-15 {
    margin-top: 15px;
}
.collapse {
	transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}